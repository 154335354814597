@use 'public/static/css/module/color';
@use 'public/static/css/module/font';
@use 'public/static/css/module/flex';

.header-title {
  display: flex;
  width: 95%;
  margin: 0 auto;
  padding: 2rem 0;

  h1 {
    @include font.font-style(28px, color.$sub800, bold);
  }
}
.section-title {
  @include flex.row-flex('', center);
  width: 100%;
  height: 35px;
  margin-bottom: 10px;

  h2 {
    @include flex.row-flex('', center);
    @include font.font-style-18;

    color: color.$sub800;
  }

  button {
    margin-left: 0.25rem;

    &:first-child {
      margin-left: 0;
    }
  }
}
