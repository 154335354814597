@use 'public/static/css/module/color';
@use 'public/static/css/module/flex';
@use 'public/static/css/module/form';

.info-field {
  @include flex.column-flex(center);
  height: 80px;
  margin-top: 10px;
  padding: 30px;
  border: 2px solid color.$sub200;
  color: color.$sub500;
  font-size: 15px;

  input {
    width: 50%;
    height: 40px;
    margin-top: 5px;
    padding: 5px;
    @include form.border(bottom);
    background-color: color.$sub100;
  }
}

.setting-field {
  margin-top: 20px;
  .top-list {
    max-height: 350px;
    border: 2px solid color.$sub200;
    overflow-y: auto;

    .list-title {
      font-size: 12px;
      background-color: color.$sub100;
    }
    li {
      height: 35px;
      padding-left: 10px;
      line-height: 35px;
      @include form.border(bottom);
      input {
        margin-right: 10px;
      }
    }
  }
}

.button-wrap {
  @include flex.row-flex(space-between, center);
  margin-top: 30px;
}
