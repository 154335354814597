@use 'public/static/css/module/color';
@use 'public/static/css/module/form';
@use 'public/static/css/module/flex';
@use 'background';
html,
body {
  font-family: Arial, Helvetica, sans-serif !important;
}
#wrap {
  position: fixed;
  height: 100%;
  width: 100%;
}

.bg-gray-wrap {
  height: calc(100% - 100px);
  background-color: #fff;
  padding: 50px;
}
.flex {
  @include flex.row-flex();
}
.mg-auto {
  margin: 0 auto;
}
.text-bold {
  font-weight: 800;
}
.section-container {
  width: calc(100% - 340px);
  height: calc(100% - 50px);
  margin-top: 50px;
  margin-left: 270px;
  padding-right: 70px;

  overflow-y: auto;
  @include form.scrollbar-style();
}
.content-wrap {
  position: relative;
  width: 90%;
  max-width: 1030px;
  margin: 0 auto;
}
.content-area {
  position: relative;
  width: 95%;
  margin: 0 auto 30px;
}
.register-btn {
  position: absolute;
  right: 0;
  margin-top: 20px;
}
