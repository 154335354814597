@use 'public/static/css/module/color';
@use 'public/static/css/module/font';
@use 'public/static/css/module/form';
@use 'public/static/css/module/theme';
@use 'public/static/css/module/flex';
.login-page-wrap {
  @include flex.row-flex(center,center);
  width: 100%;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  background-color: color.$sub100;
  color: color.$sub500;
  .main {
    @include flex.row-flex(center, center);
    width: 1000px;
    min-width: 1000px;
    height: 600px;
    min-height: 600px;
    background-color: color.$sub100;
    box-shadow: 10px 10px 10px color.$sub200, -10px -10px 10px color.$white;
    border-radius: 12px;
    overflow: hidden;
    @media (max-width: 1200px) {
      transform: scale(0.7);
    }
    @media (max-width: 1000px) {
      transform: scale(0.6);
    }
    @media (max-width: 800px) {
      transform: scale(0.5);
    }
    @media (max-width: 600px) {
      transform: scale(0.4);
    }
    .login-container {
      @include flex.row-flex(center, center);
      width: 600px;
      height: calc(100% - 50px);
      padding: 25px;
      border-right: 10px solid color.$white;
      background-color: color.$sub100;
      form {
        height: 70%;
        .login-form {
          @include flex.column-flex(space-around, center);
          width: 100%;
          height: 100%;
          .title-wrap{
            @include flex.column-flex(center,center);
          .login-form-title {
            font-size: 34px;
            font-weight: 700;
            color: color.$black;
          }
          .sub-title{
            font-size: 18px;
            font-weight: 600;
            line-height: 3;
            color: color.$blue_main600;
          }
          .false{
            font: size 18px;
            font-weight: 600;
            color:color.$red;
          }
        }
          .input-wrap {
            @include flex.column-flex(center,center);
            input {
              width: 350px;
              height: 40px;
              margin: 4px 0;
              padding-left: 25px;
              border: 1px solid color.$sub100;
              border-radius: 8px;
              font-size: 17px;
              letter-spacing: 0.15px;
              font-family: 'Montserrat', sans-serif;
              background-color: color.$sub100;
              background-color: color.$sub100;
              box-shadow: inset 2px 2px 4px color.$sub200, inset -2px -2px 4px color.$white;
              &:focus {
                box-shadow: inset 4px 4px 4px color.$sub200, inset -4px -4px 4px color.$white;
              }
            }
          }
        }
      }
    }
    .content-container {
      width: calc(100% - 660px);
      .content-title {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        line-height: 3;
        color: color.$black;
      }
      .description{
        font-size: 14px;
        letter-spacing: .25px;
        text-align: center;
        line-height: 1.6;
      }
    }
  }
}