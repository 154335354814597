@use 'public/static/css/module/flex';
@use 'public/static/css/module/color';
@use 'public/static/css/module/font';
@use 'public/static/css/module/theme';

.header-bar {
  z-index: 1001;
  display: flex;
  min-width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #142041;
  padding-left: 1rem;

  &__title {
    @include flex.row-flex(center, center);
    @include font.font-style(17.5px, color.$sub100, 600);

    color: color.$sub100;
  }

  .logo {
    display: block;
    margin-right: 10px;
    width: auto;
    height: 20px;
    line-height: 50px;
    cursor: pointer;

    img {
      width: auto;
      height: 100%;
      vertical-align: middle;
    }
  }
}

.header-bar__menu {
  display: flex;
  position: absolute;
  height: 100%;
  left: initial;
  right: 0;
  top: 0;

  &__text {
    @include font.font-style(13px, #cac5c4, 600, 50px);

    z-index: 100;
    text-align: center;
    cursor: pointer;
  }

  &__selector {
    span {
      display: block;
      position: absolute;
      right: 7px;
      top: 17px;

      > svg {
        color: color.$sub100;
        font-size: 9px;
      }
    }
  }
}

.header-bar__menu > li {
  position: relative;
  width: auto;
  min-width: 60px;
  height: 100%;

  &:hover {
    background-color: color.$yellow;
  }

  &:last-child {
    margin-right: 10px;
  }

  > a {
    position: relative;
    display: block;
    width: 60px;
    height: 100%;

    > svg {
      @include font.font-style(16px, #f7f3f2, 900);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      margin-left: -8px;
    }
  }
}

.header-bar__menu > li > select {
  @include font.font-style(13px, #cac5c4, 600, 50px);
  z-index: 100;
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 20px;
  background-color: transparent;
  text-align-last: center;
  text-align: center;
}

.logo-center {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30px;
  cursor: pointer;
}
