$white: #ffffff;
$black: #000000;
$red: #ff0000;
$brick_red: #c72a32;
$light-pink: #ffd9d9;
$green: #297e2e;
$light_green: #e5f9c6;
$dark_green: #235f62;
$columbia_blue: #c7e6e0;
$purple: #5a1f6f;
$lavender: #f0e1f5;
$light_gray_blue: #a4b9cb;
$gainsboro: #d8e1e8;
$heat_wave: #ff7a00;
$coral: #f97f50;
$papaya_whip: #ffecda;
$palm_leaf: #5ea146;
$location: #fafafa;
$yellow: #c4a404;


// gray
$sub1000: #161616;
$sub900: #262626;
$sub800: #393939;
$sub700: #525252;
$sub600: #6f6f6f;
$sub500: #8d8d8d;
$sub400: #a8a8a8;
$sub300: #c6c6c6;
$sub200: #e0e0e0;
$sub100: #f5f5f5;

// blue color
$blue_main800: #1f749e;
$blue_main700: #2384b4;
$blue_main600: #2996cc;
$blue_main500: #74b9dd;
$blue_main400: #cfecfb;
$blue_main300: #e2f3fe;
$blue_main200: #e7f5fd;
$blue_main100: #f3faff;
$blue_sub500: #878d96;
$blue_sub600: #697077;
$blue_sub700: #4d5358;
$blue_sub800: #343a3f;
$blue_sub900: #21272a;
$blue_sub1000: #121619;

// orange color
$orange_main800: #ff9900;
$orange_main700: #ffa800;
$orange_main600: #ffbc00;
$orange_main500: #ffcc00;
$orange_main400: #ffea95;
$orange_main300: #fff7ad;
$orange_main200: #fff8bd;
$orange_main100: #fffcde;
$orange_sub500: #8f8b8b;
$orange_sub700: #565151;
$orange_sub800: #3c3838;
$orange_sub900: #272525;
$orange_sub1000: #171414;
