@use 'public/static/css/module/form';
@use 'public/static/css/module/flex';
@use 'public/static/css/module/font';
@use 'public/static/css/module/color';

@mixin paginationButton {
  margin-right: 2px;
  width: 26px;
  height: 26px;
  background-color: color.$white;
  border-radius: 3px;
}

.pagination {
  @include flex.row-flex(space-between, center);

  margin-top: 2rem;
}

.pagination__select {
  position: relative;

  select {
    @include form.border;
    width: 70px;
    padding-left: 0.5rem;
    padding-right: 1rem;
    height: 26px;
    color: #393939;
    font-size: 12px;
    background-color: color.$white;
    border-radius: 3px;
  }

  .fa-sort-down {
    @include form.abs-position($top: 50%, $right: 0.4rem);

    margin-top: -7px;
    color: #ada8a8;
  }
}

.pagination__data {
  @include flex.row-flex('', center);

  &__summary {
    margin-right: 0.5rem;
    color: color.$sub800;
    font-size: 11px;

    span {
      font-weight: 700;
    }
  }
}

.pagination__data__arrow-btn,
.pagination__data__num-btn li {
  @include paginationButton;

  &:last-child {
    margin: 0;
  }

  &:hover,
  &:active {
    background-color: color.$sub200;
    a {
      color: color.$sub400;
    }
  }

  &:disabled {
    background-color: color.$white;
  }

  a {
    @include font.font-style(11.5px, color.$sub400, 600, 26px);

    display: block;
    text-align: center;
    text-decoration: none;
  }
}

.pagination__data__arrow-btn {
  svg,
  &:disabled svg {
    color: color.$sub200;
    font-size: 11px;
  }

  &:hover svg {
    color: color.$sub400;
  }
}

.pagination__data__num-btn li.active {
  background-color: color.$sub400;

  a {
    color: color.$white;
  }
}
