@use 'public/static/css/module/color';
@use 'public/static/css/module/form';
@use 'public/static/css/module/font';
@use 'public/static/css/module/flex';

.left-menu {
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 50px;
  width: 270px;
  height: calc(100% - 50px);
  background-color: color.$sub200;
  overflow-y: auto;
}

.profile {
  background: color.$white;
  @include form.border(top, $color: color.$blue_main200);
  @include form.border(bottom, $color: color.$blue_main200);

  padding: 0.5rem 0;

  &__item {
    width: 85%;
    padding: 0.4rem 0;
    margin: 0 auto;
  }
  &__flex {
    @include flex.row-flex(center, center);
  }

  &__info {
    @include font.font-style(18px, color.$yellow, 700);
  }

  &__role-badge {
    @include font.font-style(13px, color.$black, 700);
    margin-left: 5px;
    padding: 2px 7px 1px;
    line-height: 5;
    border-radius: 8px;
  }
}

.gnb {
  &__main-menu {
    a {
      text-align: center;
    }
    &.on {
      background-color: color.$yellow;
      a {
        color: color.$white;
      }
    }

    a {
      @include font.font-style(13.5px, color.$sub600, 700, 43px);

      display: block;
      padding-left: 24px;
      height: 43px;
      text-decoration: none;
      text-align: left;
      &:hover {
        background-color: color.$yellow;
      }
      &:active {
        color: color.$white;
        background-color: color.$yellow;
      }
    }
  }
}
