@use 'public/static/css/module/color';
@use 'public/static/css/module/form';

@mixin button-filled($bg, $color:white) {
  @include form.border($color: $bg);
  background-color: $bg;
  color: $color;

  &:hover:not([disabled]) {
    cursor: pointer;
    background: darken($bg, 3%);
    transition: all 0.3s ease;
  }

  &:active:not([disabled]) {
    background:darken($bg, 5%);
  }

  &:disabled {
    opacity: 0.3;
  }
}

@mixin button-outlined($color, $bg: white) {
  @include form.border($color: $color);
  background-color: $bg;
  color: $color;

  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: $color;
    color: color.$white;
  }

  &:active:not([disabled]) {
    color: color.$white;
    background-color: darken($color, 5%);
  }

  &:disabled {
    opacity: 0.3;
  }
}