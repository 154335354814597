@use 'public/static/css/module/color';
@use 'public/static/css/module/button';
@use 'public/static/css/module/font';
@use 'public/static/css/module/flex';

.common-button {
  border-radius: 3px;

  &.lg {
    @include font.font-style($size: 14px, $weight: bold);
    padding: 13px 25px 12px;
  }

  &.md {
    @include font.font-style($size: 13px, $weight: bold);
    padding: 9px 15px 8px;
  }

  &.sm {
    @include font.font-style(12px);
    padding: 8px 10px 7px;
  }

  &.xs {
    @include font.font-style(12px);
    padding: 4px 9px 3px;
  }

  &.filled {
    &.primary {
      @include button.button-filled(color.$yellow);
    }

    &.secondary {
      @include button.button-filled(color.$sub400);
    }

    &.red {
      @include button.button-filled(color.$brick_red);
    }

    &.blue {
      @include button.button-filled(color.$blue_main600);
    }
  }

  &.outlined {
    &.primary {
      @include button.button-outlined(color.$yellow);
    }

    &.secondary {
      @include button.button-outlined(color.$sub400);
    }

    &.red {
      @include button.button-outlined(color.$brick_red);
      margin-bottom: 0;
    }

    &.blue {
      @include button.button-outlined(color.$blue_main600);
      margin-bottom: 0;
    }
  }
}

.icon-btn:hover {
  opacity: 0.8;
  transform: scale(1.1);
}
