@use 'public/static/css/module/color';
@use 'public/static/css/module/flex';
@use 'public/static/css/module/form';

.go-back-btn {
  margin-right: 30px;
}

.info-field {
  height: 230px;
  margin-top: 10px;
  padding: 1.7rem;
  border: 2px solid color.$sub200;
  color: color.$sub500;
  font-size: 15px;
  &.group {
    height: 60px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
  }
  .row-content {
    flex: 0 0 auto;
    width: 50%;
  }
  .label {
    font-size: 12px;
    font-weight: 700;
    display: block;
    width: 100%;
    color: #8d8d8d;
    margin-bottom: 4px;
    cursor: auto;
  }
  .uneditable-input {
    width: 80%;
    min-height: 30px;
    padding: 6px 0.5rem 4px;
    @include form.border(bottom);
    color: #262626;
    font-size: 13px;
    font-weight: 400;
    background-color: transparent;
    word-break: break-all;
    cursor: inherit;
  }
  .editable-input {
    width: 80%;
    min-height: 30px;
    padding: 6px 0.5rem 4px;
    @include form.border(bottom);
    color: #262626;
    font-size: 13px;
    font-weight: 400;
    background-color: color.$sub100;
    word-break: break-all;
    cursor: inherit;

    input {
      width: 100%;
      background-color: inherit;
    }
  }
}

.include-btn-input {
  @include flex.row-flex(space-between, center);
  width: 80%;
  min-height: 30px;
  padding: 6px 0.5rem 4px;
  @include form.border(bottom);
  color: #262626;
  font-size: 13px;
  font-weight: 400;
  background-color: transparent;
  word-break: break-all;
  cursor: inherit;
}

.content-field {
  @include flex.row-flex(space-between);
  width: 100%;
  .content-setting {
    width: 40%;
    margin-top: 20px;
    .top-list {
      height: 315px;
      border: 2px solid color.$sub200;
      overflow-y: auto;
      .list-title {
        font-size: 12px;
        background-color: color.$sub100;
      }
      li {
        height: 35px;
        padding-left: 10px;
        line-height: 35px;
        @include form.border(bottom);
      }
      .on {
        background-color: color.$yellow;
        color: color.$white;
      }
      .border-none {
        border: none;
      }
    }
  }
  .button-wrap {
    @include flex.column-flex(space-around, center);
    padding: 50px;
  }
}
.content-table {
  width: 100%;
  margin-top: 40px;
  padding-bottom: 100px;
}

.section-title-btn {
  position: relative;
  .edit-btn-wrap {
    position: absolute;
    right: 0;
  }
}
