@use 'public/static/css/module/color';
@use 'public/static/css/module/theme';

.bg-white {
  background-color: color.$white !important;
}

.bg-sub100 {
  background-color: color.$sub100 !important;
}

.bg-sub200 {
  background-color: color.$sub200 !important;
}

.bg-orange-main700 {
  background-color: color.$orange_main700;
}

.bg-dark-green {
  background-color: color.$dark_green;
}

.bg-light-gray-blue {
  background-color: color.$light_gray_blue;
}

.bg-green {
  background-color: color.$green;
}

.bg-blue-main300 {
  background-color: color.$blue_main300;
}

.bg-blue-main600 {
  background-color: color.$blue_main600;
}

.bg-light-pink {
  background-color: color.$light-pink;
}

.bg-light-green {
  background-color: color.$light_green;
}

.bg-papaya-whip {
  background-color: color.$papaya_whip;
}

.bg-columbia-blue {
  background-color: color.$columbia_blue;
}

.bg-purple {
  background-color: color.$purple;
}

.bg-lavender {
  background-color: color.$lavender;
}

.bg-coral {
  background-color: color.$coral;
}

.bg-brick-red {
  background-color: color.$brick_red;
}

.bg-transparent {
  background-color: transparent !important;
}

.main100 {
  @include theme.themify(theme.$themes) {
    background-color: theme.themed('main100');
  }
}

.main200 {
  @include theme.themify(theme.$themes) {
    background-color: theme.themed('main200');
  }
}

.main300 {
  @include theme.themify(theme.$themes) {
    background-color: theme.themed('main300');
  }
}
