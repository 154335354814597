@use 'color';
@use 'theme';

@mixin input {
  @include border(bottom);

  padding: 6px 0.5rem 4px;
  width: 100%;
  min-height: 30px;
  color: color.$sub900;
  font-size: 13px;
  font-weight: 400;
  background-color: #fff;
  box-sizing: border-box;

  &::placeholder {
    color: color.$sub400;
    font-size: 11.5px;
  }

  &:focus {
    @include theme.themify(theme.$themes) {
      color: theme.themed('main700');
      border-color: theme.themed('main600');
      background-color: theme.themed('main200') !important;
    }
  }

  &:disabled {
    opacity: 1;
    background-color: transparent;
    cursor: inherit;

    &::placeholder {
      opacity: 0;
    }
  }
}

@mixin checkbox-checked {
  &:checked {
    @include theme.themify(theme.$themes) {
      background-color: theme.themed('main800');
      border-color: theme.themed('main800');
    }

    outline: 0;
  }

  &:focus {
    @include theme.themify(theme.$themes) {
      border-color: theme.themed('main800');
    }

    outline: 0;
    box-shadow: none;
  }
}

@mixin border($direction: false, $width: 1px, $style: solid, $color: color.$sub200) {
  @if $direction {
    border-#{$direction}: $width $style $color;
  } @else {
    border: $width $style $color;
  }
  box-sizing: border-box;
}

@mixin abs-position($top: '', $right: '', $bottom: '', $left: '') {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin scrollbar-style() {
  &::-webkit-scrollbar {
    width: 9px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: color.$sub200;
  }

  &::-webkit-scrollbar-track {
    background-color: color.$sub100;
    box-shadow: inset 0px 0px 5px white;
  }
}
