@use 'color';

// theme config
// 테마 색상에따라 달라지는 컬러들을 정의한다
$themes: (
  orange: (
    main100: color.$orange_main100,
    main200: color.$orange_main200,
    main300: color.$orange_main300,
    main400: color.$orange_main400,
    main500: color.$orange_main500,
    main600: color.$orange_main600,
    main700: color.$orange_main700,
    main800: color.$orange_main800,
    main_sub700: color.$orange_sub700,
    main_sub800: color.$orange_sub800,
    main_sub900: color.$orange_sub900,
    main_sub1000: color.$orange_sub1000,
  ),
  blue: (
    main100: color.$blue_main100,
    main200: color.$blue_main200,
    main300: color.$blue_main300,
    main400: color.$blue_main400,
    main500: color.$blue_main500,
    main600: color.$blue_main600,
    main700: color.$blue_main700,
    main800: color.$blue_main800,
    main_sub700: color.$blue_sub700,
    main_sub800: color.$blue_sub800,
    main_sub900: color.$blue_sub900,
    main_sub1000: color.$blue_sub1000,
  ),
);

// mixin - return styled markup
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{'' + $theme} & {
      // 전역에서 테마를 사용할 수 있도록 해당 클래스 이름을 가진 element 로 wrapping 해야함
      $theme-map: () !global;
      // key : ORANGE, submap : text-color, background-color
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global; // 병합
      }
      @content; // mixin 에 정의되지 않은 스타일에 추가로 스타일을 넣을 수 있도록
      $theme-map: null !global;
    }
  }
}

// map-get(map,key) : 키에 해당하는 값을 값을 리턴하는 함수
@function themed($key) {
  @return map-get($theme-map, $key);
}
