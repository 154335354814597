@use 'public/static/css/module/color';
@use 'public/static/css/module/form';
@use 'public/static/css/module/font';

.table-overflow-scroll {
  overflow-x: auto;

  th {
    text-align: -webkit-match-parent;
  }

  @include form.scrollbar-style();
  @include form.border($width: 2px);

  .table-border {
    border: 0;
  }

  .tbody-scroll,
  .tbody-scroll-long {
    overflow-y: auto;

    @include form.scrollbar-style();
  }

  .tbody-scroll {
    max-height: 188px;
  }

  .tbody-scroll-long {
    max-height: 840px;
  }
}

.table-border {
  @include form.border($width: 2px);

  table-layout: fixed;
  width: 100%;

  > tbody > tr {
    @include form.border(bottom);

    &:last-child {
      border-bottom: none;
    }
  }

  > thead[data-column-type='append'] > tr > th:last-child::after {
    content: '';
    width: 44px;
    height: 44px;
    position: absolute;
    top: 130px;
    right: -20px;
  }

  .form-check-input {
    @include form.checkbox-checked;

    width: 15px;
    height: 15px;
    margin: 0 auto;
  }

  .file-upload {
    margin-bottom: 0;
  }

  .upload-file-input {
    border: 0;
  }
}

.table-border > thead th {
  @include font.font-style(14px, color.$sub800, 700);
  @include form.border(right);
  @include form.border(bottom);

  padding: 0.75rem 0.8rem 0.6rem;
  height: 42px;
  background-color: color.$sub100;
  word-wrap: break-word;
  word-break: keep-all;

  &:last-child {
    border-right: 0;
  }
}

.table-border > tbody > tr > td {
  @include font.font-style(13px, color.$sub900, 500);
  @include form.border(right);

  vertical-align: middle !important;
  padding: 0.7rem 0.8rem 0.6rem;
  height: 42px;
  word-break: break-all !important;

  &:last-child {
    border-right: 0;
  }
}

.information-table-more {
  padding: 0 !important;
  text-align: center !important;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  right: -2px;
  background-color: white;

  button {
    padding: 5px 9px !important;
    font-weight: bold !important;

    &:hover svg {
      color: #ffffff;
    }
  }

  button.clipboard-write-btn:hover svg {
    color: inherit;
  }
}

.row-table {
  width: 100%;
  table-layout: fixed;
  padding: 2rem;

  > tbody > tr {
    > td {
      padding: 0.7rem 0.8rem 0.6rem;
      vertical-align: middle;
    }

    border-top: 1px solid color.$sub200;
    border-bottom: 1px solid color.$sub200;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.table-border.sub200-bg {
  th {
    background-color: color.$sub200;
  }

  &.position-sticky {
    th {
      @include form.border($color: color.$sub300);

      border-top: 0;
      border-left: 0;
    }

    thead tr {
      &:first-child th:last-child,
      &:only-child th:last-child {
        border-right: 0;
      }
    }
  }
}

.attach-file-link__td {
  &__label {
    display: flex;
    align-self: center;
    color: #2996cc;
    font-size: 12px;
    font-weight: 700;
    text-decoration: underline;
    background-color: transparent;
    word-break: break-all;
    cursor: pointer;

    &:hover {
      color: #196c96;
    }
  }
}
