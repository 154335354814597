$font-size-13: 13px;
$font-size-15: 15px;
$font-size-18: 18px;
$font-size-20: 20px;

@mixin font-style($size, $color: false, $weight: normal, $line-height: false) {
  font-size: $size;
  font-weight: $weight;

  // $color
  @if (type-of($color) == color){
    color: $color;
  }

  @if (type-of($line-height) == number or type-of($line-height) == string){
    line-height: $line-height;
  }
}

@mixin font-style-13() {
  @include font-style($size: $font-size-13);
}

@mixin font-style-15() {
  @include font-style($size: $font-size-15);
}

@mixin font-style-18() {
  @include font-style($size: $font-size-18, $weight:bold);
}

@mixin font-style-20() {
  @include font-style($size: $font-size-20, $weight:bold);
}